/**
 * Add / override style in uu-system-style.0.1.min.css
 */

@media print {
    .no-print { display: none; }
    div.no-print { display: none; }
    .tableStyle td { border-bottom: 1px solid #ddd; }
    h1 {
        font-size: 16pt;
    }
    .row {
        contain: size;
    }
    .jumbotron {
        background: transparent;
        padding: 10px 0;
    }
    .d-flex.justify-content-center {
        justify-content: left !IMPORTANT;
        /*margin-left: -10px;*/
    }
    .kurskod {
    }
    th {
        white-space: nowrap;
    }
    h2.paperHeader.printout-header {
        visibility: visible;
        position: static;
        opacity: 1;
    }
    th .printout-header {
        visibility: visible;
        position: static;
        opacity: 1;
    }
    th .extra-header {
        visibility: hidden;
    }
    table, td, th {
        //table-layout: fixed;
        font-size: .9em;
    }
    table tr th:nth-child(2){
        width: 220px;
    }
}

.printout-header {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

/* .col-md-8 { background: #f5f5f5; padding: 20px; } */

.ttt { background: lightgrey; margin-left: 0; }

h1 {
    clear: both;
}
h2 {
    margin-bottom:5px;
}
h2.kurskod {
}
h2.paperHeader {
    margin-bottom: 5px;
    font-family: arial, sans-serif;
    font-size: 1.3rem;
}
.layout-main-container {
    margin-top:10px;
    padding-bottom:100px;
}

/****** Header ******/
header.layout-header {

}
header .layout-logo	{
    /*margin-left:0;*/
}
header h1 {
    display: inline-block;
    font-family: "BerlingRoman", serif;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    margin-bottom: 0;
    margin-top: 70px;
    text-decoration: none;
    border: none;
}
header nav ul {
    padding: 0;
    width: 100%;
    max-width: 1200px;
}
header nav ul li span{
    font-weight: 500;
}
header nav ul li a{
    font-weight: 300;
    color:#333;
    text-decoration:none;

}
header nav ul li a:hover {
    background:#fff;
}
header nav ul li a, header nav ul li span {	
    padding: 10px 20px;
}
header nav ul li:first-child  span, header nav ul li:first-child  a{
    padding-left: 0;
}
.nobullet {
    list-style-type: none;
}
.layout-header {

}
.bg-dark, .bg-dark a {
    color: #fff;
    text-decoration: none;
    height: 28px;
}

/****** Toppmenyn i headern ******/
nav.col-md-3.topMenu {
    position: absolute;
    top: 2px;
    right: 0;
    font-weight: 400;
    font-size: initial;
}
nav.col-md-3.topMenu ul {
    list-style: none;
    font-weight: 400;
}
nav.col-md-3.topMenu ul li a {
    color: #fff;
    font-weight: 400;
}
nav.col-md-3.topMenu ul li a:hover {
    color: #fff;
    text-decoration: underline;
    background: transparent;
}

/******* Brödsmulor- och länk-block ********/
nav.breadcrumb-links {
    font-size: 0.9em;
}
nav.breadcrumb-links ul.breadcrumbs {
    list-style: none;
    float: left;
}
nav.breadcrumb-links ul.div-links {
    list-style: none;
    float: right;
}
nav.breadcrumb-links ul {
    list-style: none;
    padding: 0;
}
nav.breadcrumb-links ul li {
    float: left;
    margin: 0 10px 0 0;
}

/******* Annan navigation ********/
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
nav p.rapport {
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 3px;
}
nav p.rapport a {
    float: right;
}
nav p.rapport a .button-slim {
    padding:2px 10px;
}

/****** Formulär och liknande-style ******/

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: white;
    width: 850px;
    height: 850px;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 25px 30px 10px 20px;
}
.bold-font {
    font-weight: bold;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

div.enrollment-filter {

}

.enrollment-filter .col-md-3 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.row.filter-select {
    background:#f5f5f5;
}

.toggle-filter .border-top:first-child {
    border-top: none !IMPORTANT;
}
.search-filters-selected {
    border: 1px solid #ddd;
    border-radius: 4px;
}
.search-filters-selected::before {
    border-top: none;
}
.search-filter-selected, .search-filter-selected:hover {
    display: inline;
}
.toggle-header+.toggle-content .card {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top: 1px solid #dddddd;
}

/*
fieldset {
    padding:70px 20px 20px;
    position: relative;
}*/

legend {
    position: absolute;
    top: 15px;
}

input[type=text], input[type=date] {
    border-color: #999;
    margin: 0;
    padding: 5px;
}
input[disabled] {
    border-color: #e5e5e5;
    color:#999;
}
input[type=checkbox] {
    vertical-align: top;
    margin-right: 5px;
    margin-top: 4px;
    margin-left: 5px;
    min-width: 18px;
    min-height: 18px;
}
span.nav-link {
    font-weight: 500;
}
span.inline-info {
    font-style: italic;
    color: #900;
}
label select, label input, label input[type="date"] {
    margin-left: 10px;
}
select {
    height:36px;
}

/****** Alert-rutor ******/
.alert-container {
    margin-top: 10px;
    clear: both;
}
.alert-loading {
    font-size: 1.1em;
    font-weight: 500;
    text-align: center;
    transition: 1s ease-in-out;
}
.alert-loading {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
	color: black;
    }
    50% {
	color: #ccc;
    }
    100% {
	color: black;
    }
}

/****** Blandat ******/
.enrollment-filter-todo {
    font-size: 75%;
}
.formWrapper {
    margin-top:0;
}
.divWrapper {
    clear: both;
}
.tableWrapper .divWrapper {
    /*overflow-x: scroll;*/
    overflow-x: initial;
}
.linkWrapperTop {
    clear: both;
    font-size: .9em;
    background: #333;
    border-bottom: 1px solid #ccc;
    color: #fff;
    margin: 0;
    padding: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index:10;
}
.linkWrapperTop span{
    font-weight: 500;
}
.linkWrapperTop a.sticky{
    padding: 5px 10px;
    text-decoration: underline;
    font-weight: 400;
    color: #fff;
}
.linkWrapperTop a.sticky:hover{
    background:#000;
}
.linkWrapperTop a.sticky::before{
}
.linkWrapper {
    clear:both;
    padding-top: 20px;
    display: none;
}
.arrowUp, .arrowDown {
    margin-right:20px;
    font-size: 0.9em;
}
.arrowUp::before, .arrowDown::before {
    background-size: 18px;
    content: ' ';
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    vertical-align: bottom;
    width: 20px;
    opacity:0.3;
}
.arrowUp::before {
    background-image: url(../img/arrow_upward-24px.svg);
}
.arrowDown::before {
    background-image: url(../img/arrow_downward-black-24dp.svg);
}
.tableWrapper {
    margin-bottom: 30px;
    clear: both;
    padding-bottom:20px;
}
.tableWrapper h3 {
    padding-top: 40px;
}
.updateSelectedWrapper {
    font-weight: 400;
}
.gradeInfo {
    margin:20px 0;
}
span.updateSelected {
    margin: 20px 0;
    float: left;
    padding: 4px 0 5px 50px;
    background: url(../img/list.png) no-repeat 10px	8px;
    background-size: 20px;
}
span.gradeInfo.gradeInfoGrade {
    float: left;
    padding-left: 30px;
}
span.gradeInfo.gradeInfoDate {
    float: left;
    margin-left: 30px;
}

/****** Knappar ******/
.buttonWrapper {
    clear: both;
    margin-top:20px;
    float: right;
}
button {
    margin:5px;
}
/*button.update-button.button-outline.button-image::before {
	background: url("/img/autorenew-white-18dp.svg") no-repeat center center;
	filter: brightness(0.3) sepia(.5) hue-rotate(170deg) saturate(9);
	content: ' ';
}*/
button.button-image {
    padding: 2px 3px;
    margin: 0 10px;
    vertical-align: top;
}
button.button-image img{
    height:26px;
    width:26px;
    filter: brightness(0.3) sepia(.5) hue-rotate(170deg) saturate(9);
}
button.button-image:hover img{
    filter: none;
}
button.button-image[disabled] img{
    filter: brightness(0) sepia(.5) hue-rotate(170deg) saturate(9);
    opacity: .7;
}
/****** Columner ******/
.list-columns {
 column-count: 3;
}

/****** Tabellstyle ******/
thead tr a{
    color:#333;
}

table td + td { border-left:1px solid lightgray; }
/*
table.tableStyle {
    width: 100%;
    border-bottom: 5px solid #e5e5e5;
}
.tableStyle td,.tableStyle th {
    padding: 10px 5px;
    font-size:0.9em;
    vertical-align: middle;
    font-weight: 400;
}
.tableStyle td {
    border-right:1px solid #ddd;
}
.tableStyle th {
    border-right:1px solid #fff;
}
.tableStyle thead tr th{
    font-weight: 500;
    padding-right:20px;
}
table.tableStyle tbody tr:nth-child(even) {background: #f0f0f0}
*/
.emailColumn {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    word-break: break-all;
    cursor: pointer;
}
.emailCheckbox {
    cursor: pointer;
}
th.col-3-4 {
    width: 250px;
}
th.col-4-4 {
    width: 180px;
}
th.col-pnr {
    width: 150px;
}
th.col-date {
    width: 180px;
}
.tableStyle thead tr th.checkbox {
    min-width: 20px;
    width:35px;
    padding-right:0;
}
th.projectTitleHeader,th.gradingDate {
    min-width:200px;
}
th.reportName {
    min-width: 200px;
}
th.reportCivic {
    min-width: 140px;
}
th.commonGrade, th.commonStatus {
    min-width: 80px;
}
th.assignmentHeader {
    min-width:170px;
}
.edit-column-visible {
    display: block;
}
.edit-column {
    display: none;
}
.edit-column-hand {
    cursor: pointer;
}
.edit-extra-header {
    display: flex;
}
td.submission-excused {
    background: #f9f9ce;
}
td.submission-insufficient {
    background: #f9cecf;
}
tr.selected td.submission-excused, tr.resultSaved.selected td.submission-excused {
    background: repeating-linear-gradient(
        -55deg,
        #fbfbdc,
        #fbfbdc 10px,
        #f9f7bb 10px,
        #f9f7bb 20px
    );
}
tr.resultSaved  {
    border-bottom:1px solid #dbe8db;
    border-left: 3px solid #b7ccb7;
}
tr.resultSaved td {
    background:#e7f1e7;
}
tr.resultSaved td div.resultSaved {
    color: #155724;
    font-style: italic;
    font-size: .9em;
    background: #c8e9c8;
    padding: 5px;
}
tr.resultSaved td.submission-excused {
    background:#f9f9ce;
}
tr.resultSaved.selected td {
    background: repeating-linear-gradient(
	-55deg,
	#eef7ee,
	#eef7ee 10px,
	#e7f1e7 10px,
	#e7f1e7 20px
    );
}
tr.attested td div.attested {
    font-style: italic;
    font-size: .9em;
    padding: 5px;
}
tr.selected {
    border-bottom: 1px solid #eee;
}
tr.selected td {
    background: repeating-linear-gradient(
	-55deg,
	#f5f5f5,
	#f5f5f5 10px,
	#eee 10px,
	#eee 20px
    );
}
tr.selected td.submission-insufficient {
    background: repeating-linear-gradient(
	-55deg,
	#f9bbbb,
	#f9bbbb 10px,
	#eee 10px,
	#eee 20px
    );
}

tr.resultError td {
    color: #721c24;
    background-color: #f8d7da;
}
tr.selected.resultError td {
    background: repeating-linear-gradient(
	-55deg,
	#f8e0e2,
	#f8e0e2 10px,
	#F8EAED 10px,
	#F8EAED 20px
    );
}

.icon-edit::before,
.icon-edit::after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGFnZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7fQ0KCS5zdDF7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwwaDI0djI0SDBWMHoiLz4NCjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0xNC4xLDlMMTUsOS45TDUuOSwxOUg1di0wLjlMMTQuMSw5IE0xNy43LDNjLTAuMywwLTAuNSwwLjEtMC43LDAuM2wtMS44LDEuOGwzLjgsMy44TDIwLjcsNw0KCWMwLjQtMC40LDAuNC0xLDAtMS40bC0yLjMtMi4zQzE4LjIsMy4xLDE3LjksMywxNy43LDN6IE0xNC4xLDYuMkwzLDE3LjNWMjFoMy44TDE3LjgsOS45QzE3LjgsOS45LDE0LjEsNi4yLDE0LjEsNi4yeiIvPg0KPC9zdmc+DQo=);
}

/*/
.resultError.alert-danger {
    font-size: .9em;
    padding: 5px;
    font-style: italic;
}

/****** Responsiv style *******/
@media (max-width: 992px) {
    .container {
	max-width: 840px;
    }
    header h1 {
	font-size: 1.5rem;
	margin: 70px;
	padding-bottom: 20px;
    }
    header nav ul {
	padding: 0;
	width: 100%;
	max-width: 1200px;
    }
    .gradeInfo {
        margin:10px 0;
    }
    span.updateSelected {
        margin: 10px 0;
        width:100%;
    }
    span.gradeInfo.gradeInfoGrade {
        padding-left: 10px;
    }
    span.gradeInfo.gradeInfoDate {
        margin-left: 30px;
    }
}

footer .version {
    color: #666;
}

@media(max-width: 768px){
    header h1{
	margin-top: 110px;
    }
    a.layout-logo.logo.logo-tag-style.logo-shadow.logo-pull-up {
	margin-top: -3rem;
	margin-left: 0;
    }
    .gradeInfo {
        margin:5px 0;
    }
    span.updateSelected {
        margin: 5px 0;
        width:100%;
    }
    span.gradeInfo.gradeInfoGrade {
        padding-left: 10px;
    }
    span.gradeInfo.gradeInfoDate {
        margin-left: 10px;
    }
    .linkWrapperTop a.sticky {
	display: block;
    }
    .linkWrapperTop span.divider {
	display: none;
    }
    .list-columns {
        column-count: 1;
    }
}
